.wide-image .block-img>img {
    @apply h-24;
    @apply md:h-72;
    @apply min-w-320;
    @apply md:w-full;
    @apply object-cover;
}

.reference-image img {
    @apply object-cover;
    @apply w-full;
    @apply h-32;
    @apply md:h-60;
}